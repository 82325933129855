.items{
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.items .item{
    width: 95%;
    margin-bottom: 1vh;
    height: 12vh;
    display: flex;
    margin-top: 2vh;
    padding-left: 2vw;
    padding-top: 1vh;
    border: solid 1px black;
    border-radius: 15px;
    align-items: center;
}
.items .item .sname{
    font-size: 2rem;
    height: 90%;
    width: 35%;
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis; 
    margin-top: 10px;
}
.item button{
    margin-left: 15vw;
    width: 15vw;
    height:7vh;
}

.item h3{
    width: 20vw;
    margin-left: 2vw;
    font-size: 1rem;
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis; 
}