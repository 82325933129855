.profile{
    height: 100vh;
    background-color:#fff;
}
.profile .upper{
    width: 100vw;
    height: 45vh;
    display: flex;
}
.upper .avatar{
    width: 30vw;
    height: 100%;
    justify-content: center;
    justify-items: center;
    padding-left: 10%;
    padding-top: 2%;
}
.avatar .ava-pic{
    width: 100px;
    height: 100px;
    background-image: url("https://s3.ap-southeast-2.amazonaws.com/cdn.greekherald.com.au/wp-content/uploads/2020/07/05194617/default-avatar.png");
    background-size:cover;
    background-position: center;
    border-radius: 50%;
}
.avatar button{
    width: 17vw;
    height: 5vh;
    margin-top: 5px;
    font-size: medium;
    font-style: italic;
}
.profile .desc {
    padding-left: 7vw;
    padding-top: 3.3vh;
    
}
.desc h2{
    width: 45vw;
    font-family: 'Times New Roman', Times, serif;
    
}
.desc h1{
    color: black;
    border-bottom: 0.5vh solid #919fcc;
}
.navies{
    width: 90%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    
    padding-bottom: 0;
    border-bottom: 3px solid rgb(172, 167, 173);
    margin-left: 5.5vw;
    margin-top: 1vh;
}
.navies .nav-sub{
    margin-left: 2vw;
    width: 15vw;
    cursor: pointer;
    
    font-size: medium;
    font-weight: 400;
    font-family:'Times New Roman', Times, serif;
}

.BookList{
    margin-left: 5.5vw;
    width: 90%;
    height: 35vh;
    background-color: #908ea760;
    padding: 1vh;
    overflow-y: scroll;
}

.bookingTable .book-link td:hover {
    background-color: #dfa6d1;
}
.bookingTable th{
    font-size: 1rem;
    width:25vw;
    border-bottom: 0.1vh solid #3b4979;
}
.bookingTable .book-link td{
    padding-top:1vh;
    padding-bottom: 1vh;
    align-items: center;
    justify-content: center;
}
.bookingTable td {
    font-size: 1rem;
    width: 25vw;
    border-bottom: 0.1vh solid #919fcc;
    padding-left: 12vh;
}

.bookBTN{
    margin-top: 1%;
    width: 70%;
    height: 13%;
    margin-left: 15%;
    background-color:#729eb2;
    color: aliceblue;
}
@media only screen and (max-width: 810px){
    .profile .upper{
        flex-direction: column;
    }
    .desc h2{
        width: 70vw;
        display: block;
        max-height: 55vh;
        padding-right: 1vw;
        overflow: hidden;
        line-height: 4vh;
        text-overflow: ellipsis;  
    }
    .navies{
        margin-top: 55vh;
    }
    .booking .book-link {
        padding-right: 5vh;
    }
}

.profile-container{
    background: #e4e5f1;
    border-radius: 7px;
}

.profile-container > button{
    font-size: 18px;
}