.ser-content #title {
    padding: 3rem 0;
    text-align: center;
    font-weight: lighter;
    font-size: 3rem;
    /* font-weight: bold; */
    color: #112A46;
}

.event-detail .img-wrapper {
    min-width: 50%;
}

.event-detail {
    display: flex;
    padding: 2rem 10rem
}

.event-info {
    padding-left: 2rem;
}

.event-img {
    width: 100%;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-info > p {

}

.user-avatar {
    border-radius: 999rem;
    width: 48px;
    margin-right: 1rem;
}

.event-title {
    font-size: 1.5rem;
}

.event-location {
    font-size: 1.25rem;
}

.event-description {
    font-size: 1.5rem;
}

.event-detail p {
    margin: 1rem 0;
}

.event-detail button {
    background-color: rgb(172, 178, 243);
    border: none;
    color: black;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    border-radius: 4px;
}