@import url('https://fonts.googleapis.com/css?family=Outfit');


.hero-container {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    align-items: center;
    margin-bottom: 5rem;
}

.hero-container h1 {
    font-family: "Outfit", sans-serif;
    color: #fff;
    margin-bottom: 0.1rem;
    margin-top: 1rem;
}

.hero-container p {
    font-family: "Outfit", sans-serif;
    color: #fff;
    font-size: 28px;
    font-weight: lighter;
}

.top-text {
    text-align: center;
    font-weight: 200;
}

.top-text p {
    margin: 0;
    font-size: 24px;
    margin-bottom: 5rem;
}

.top-text h1 {
    font-weight: lighter;
    font-size: 48px;
    margin-bottom: 0;
}

#search-btn {
    margin: 0 auto;
    border: .0625rem solid rgba(255, 255, 255, .4);
    outline: none;
    position: relative;
    width: 70%;
    display: flex;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.3);
    height: 8rem;
}

#search-btn input {
    font-size: 24px;
    margin-left: 3rem;

    width: 100%;
    height: 50%;
    border: none;
    justify-content: center;

    margin: auto;
    width: 80%;

}

#search-btn Button {
    color: white;
    background-color: #00D084;
    border: 1px solid red;
    margin: auto;
    width: 100%;
    margin-right: 20px;
    border-radius: 3px;
    border-style: none;
}


textarea:focus, input:focus {
    outline: none;
}


#category-select, #date-select, #guest-select, #budget-select {
    background-color: #ffff;
    border: .0625rem solid rgba(255, 255, 255, .4);
    width: 25%;
    background-color: rgba(0, 0, 0, 0.3);
}

#category-select p, #date-select p, #guest-select p, #budget-select p {
    font-weight: lighter;
    color: white;
    font-size: 18px;
    padding-left: 1rem;
    padding-top: 1rem;
}

#category-select select {
    margin-right: 1rem;
    margin-left: 1rem;
    justify-content: center;
    width: 80%;
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border-bottom: .0625rem solid #fff;
}

#date-select input {
    margin-left: 1rem;
    justify-content: center;
    width: 80%;
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border-bottom: .0625rem solid #fff;
}

#guest-select input {
    margin-right: 1rem;
    margin-left: 1rem;
    justify-content: center;
    width: 80%;
    height: 40px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border-bottom: .0625rem solid #fff;
}

#guest-select input::placeholder {
    color: white;
}

#budget-select input {
    margin-right: 1rem;
    margin-left: 1rem;
    justify-content: center;
    width: 80%;
    height: 40px;
    color: white;
    width: 90%;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 2px solid white;
}

#budget-select input::placeholder {
    color: white;
}

.search-container {
    margin: 0 auto;
    border: none;
    outline: none;
    position: relative;
    width: 70%;
    display: flex;
    flex-direction: row;

}


.AdvEventType::-webkit-input-placeholder {
    color: white;
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* each search fields */


.twoSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3vh;
}

.twoSearch a {
    color: white;
    font-size: 16px;
    margin-top: 0vh;
}

.twoSearch a:hover {
    color: #1890ff;
}


.eventType option {
    background-color: #ffffff;
    color: rgb(143, 143, 143);
    -webkit-appearance: none;
}

.eventType option:hover {
    background-color: black;
    color: white;
}


.fa-play-circle {
    margin-left: 4px;
}

.AdvSearch {
    display: flex;
    flex-direction: column;
}

.ant-modal-body .AdvEventType {
    margin-bottom: 2vh;
    height: 5vh;
    background-color: #7691C620;
    border-radius: 5px;
    color: gray;
}

.ant-modal-body label {
    font-size: 1.6rem;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70px;

    }

    .hero-container>p {
        margin-top: -6vh;
        font-size: 1.5rem;
    }

    .requires {
        flex: 24%;

    }

    .search {
        margin-left: -7vw;
        margin: 0vw 1vw;
    }

    .hero-container {
        height: 100%;
    }

    .twoSearch .btn {
        margin-left: 28.5vw;
        margin-top: 1vh;
        margin-bottom: 1vh;
        width: 45vw;
    }

    .twoSearch a {
        margin-left: 35vw;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
    }

    .hero-container>p {
        font-size: 1rem;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .hero-container {
        height: 100%;
    }

    .twoSearch .btn {
        margin-left: 28.5vw;
        margin-top: 3vh;
        margin-bottom: 1vh;
        width: 45vw;
    }

    .requires {
        flex: 24%;

    }

    .twoSearch a {
        margin-left: 22vw;
    }
}

#cant-find-text {
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
}

#search-btn {
    border: .0625rem solid rgba(255, 255, 255, .4);
    border-bottom: none;
}