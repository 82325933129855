.regiBase{
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 5vh;
	background-image: url("https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
	background-size: cover;
	background-repeat: no-repeat;
}
.regiBox{
	display: flex;
	width: 64%;
	height: 90%;
	background: #FFFFFF;
	border: .4rem solid #efefef;
}

.regiBox .left{
	width: 45%;
	height: 100%;
	background-image: url("https://images.unsplash.com/photo-1543599538-a6c4f6cc5c05?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80");
	background-size: cover;
}

.regiBox .right{
	margin-left: 50px;
	height: 100%;
	width: 65%;
}
.regiBox .f-user{
	margin-top: 4vh;
	margin-bottom: 2vh;
}
.regiBox .right .form{
	overflow-y: scroll;
	height: 50vh;
	padding: 2vh;
}
.regiBox .form .item{
	margin-top: 1vh; /*上间距*/
	display: flex;
	flex-direction: column;
}
.right .sign-notice{
	width: 90%;
}
.regiBox .form .item input{
	width: 90%;
	height: 6.5vh;
	font-size: 1.5rem;
	border: .2rem solid #efefef;
	padding: 10px;
	margin-bottom: 2vh;
}

.regiBox h3{
	color: #000000;
   	font-size: 3rem;
	margin: 1rem;
	font-weight: 500;
}

.sending .submit{
	width: 90%;
	height: 50px;
	font-size: 20px;
	font-weight: 700;
	background-color: #2B394E;
    color: #FFFFFF;
	border: 0;
	cursor: pointer;
}
.left .home h4{
	margin-left: 5vw;
	margin-top: 2vh;
}
