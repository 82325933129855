@import url('https://fonts.googleapis.com/css?family=Outfit');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}


.navBar {
    position: relative;
    color: #112A46;
    align-items: center;
    background-color: #E4E5F1;
    box-sizing: border-box;
    color: #112A46;
    display: flex;
    font-family: "Outfit", sans-serif;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.navBar ul {
    color: #112A46;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    list-style: none;
}

.navBar a, li a {
    text-decoration: none;
    color: #112A46;
    display: inline-block;
    padding: 10px 30px;
    transition: all 0.3s ease 0s;
}

.navBar a::after {
    background: none repeat scroll 0 0 transparent;
    margin-top: 0.5rem;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: relative;
    background: #112A46;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.navBar a:hover::after {
    width: 100%;
    left: 0;
}

.navBar img {
    width: 8%;
    height: auto;
}

.site-logo {
    margin-left: 50px;
}