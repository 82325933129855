@import url('https://fonts.googleapis.com/css?family=Outfit');

* {
   font-family: "Outfit", sans-serif;
}

.Home {
   display: flex;
   flex-direction: column;
   background-image: url('https://imgtr.ee/images/2023/08/16/d8f9a4f49f64defb85aaa00b84a82725.jpeg');
   align-items: center;
   margin-bottom: 5rem;

}

#featured-events {
   font-family: "Outfit", sans-serif;
   color: #112A46;
   text-align: center;
   margin-bottom: 5rem;


}