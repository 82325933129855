.PersonList{
    margin-left: 5.5vw;
    width: 90%;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #908ea760;
    border-radius: 10px;
    align-items: center;
    padding-top: 5vh;
}
.List{
    width: 50vw;
    height: 30vh;
    display: flex;
}

.List .left{
    display: flex;
    flex-direction: column;
    padding: 1%;
    width:47%; height: 90%;
}
.List .right{
    display: flex;
    flex-direction: column;
    padding: 1%;
    width:47%; height: 90%;
}

.PersonList button{
    width: 65%;
    height: 7vh;
    margin-bottom: 3vh;
    background-color:#729eb2;
    color: #ffffff;
}