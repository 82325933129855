@import url('https://fonts.googleapis.com/css?family=Outfit');

.footer-container {
    background-color: #E4E5F1;
    font-family: "Outfit", sans-serif;
    color: #112A46;
    padding-bottom: 4rem;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
    /*delete if not workign*/
    /* position: fixed;
    bottom: 0;
    width: 100%;
    height: 200px;
    background: #6cf; */

}

.footer {
    background-color: #E4E5F1;
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    padding: 40px 50px;

}

.footer-heading {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;

}

.footer-heading h2 {
    font-size: 16px;
    margin-bottom: 2rem;
}

.footer-heading a {
    text-decoration: none;
    margin-bottom: 0.5rem;
    color: #112A46;
}

.footer-heading a:hover {
    color: red;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
    font-size: 16px;
}

#footer-email {
    width: 250px;
    height: 40px;
    border-radius: 4px;
    outline: none;
    border: none;
    padding-left: 0.5rem;
    font-size: 16px;
    margin-bottom: 1rem;
}

.footer-email-form {
    margin-right: 50px;
}

#footer-email::placeholder {
    color: #b1b1b1;
}

.footer-email-form p {
    margin-top: 0.2rem;
    font-size: 13px;
}

#footer-email-btn {
    width: 100px;
    height: 40px;
    border-radius: 4px;
    background-color: #389bd9;
    outline: none;
    border: none;
    font-size: 1rem;
    margin-left: 10px;
    margin-right: 10rem;
}

#footer-email-btn:hover {
    color: white;
    cursor: pointer;
    background-color: red;
    transition: all 0.4s ease-out;
}

.social-links a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #112A46;
    transition: all 0.5s ease;
}

.social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
}

@media screen and (max-width: 1080px) {
    .footer-email-form {
        margin-top: 4rem;
    }
}

@media screen and (max-width: 820px) {
    .footer {
        padding-top: 2rem;
    }
}

#mailchimp{
    height: 200px; 
    border: 2px solid red; 
}