/* .review{
    height: 85vh;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.review #nav {
    padding: 3rem 0;
    text-align: center;
    font-weight: lighter;
    font-size: 3rem;
    color: #112A46;
    background: rgb(172, 178, 243)
}

.review #rate {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#avgRage {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex:1;
    flex-direction: column;
}

#avgRage .left {
    /* width: 0%; */
    flex: 1;
}

#avgRage .avgItem {
    width: 100%;
    display: flex;
    align-items: center;
}

#avgRage .right {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.review #statics {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .review #images {
    flex: 1;
    max-width: 50%;
    
} */
#images {
    flex: 1;
    margin-left: 5rem;
    max-width: 50%;
    height: 400px;
}

.review #content {
    padding: 2rem;
}

.review p {
    font-size: 2rem;
    margin-bottom: 0;
}

.reviewItem {
    display: flex;
    margin-bottom: 3rem;
}

#review-title {
    padding-left: 20rem;
    margin-bottom: 2rem;
}

.reviewItem .left {
    display: flex;
    min-width: 30%;
    padding-left: 2rem;
    justify-content: start;
    align-items: center;
}

.reviewItem .left .avatar {
    margin-right: 2rem;
}

.reviewItem .right {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.reviewItem p {
    font-size: 1.2rem;
}

.reviewItem .right {

}

#add-review-button {
    background-color: rgb(0, 208, 132);
    border: none;
    color: white;
    padding: 0.5rem 2rem;
    margin-left: 2rem;
    font-size: 1.5rem;
    border-radius: 4px;
}

#result-banner-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    filter: brightness(60%);
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
}

#intro-title {
    margin-top: 20px;
    text-align: center;

    display: flex;
    flex-direction: column;

}

#intro-title h2 {
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    font-family: "Outfit", sans-serif;
    font-size: 36px;
    margin-bottom: 0px;
}

#intro-title p {
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 0px;
}
