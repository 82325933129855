#message-container {
    display: flex;
    justify-content: center;
    margin-top: 50px; 
    margin-bottom: 50px;
}

#message-box {
    width: 1100px;
    height: 330px;
    background-color: #E4E5F1;
    border-radius: 3px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#message-box h1 {
    margin-left: 50px;
    font-size: 22px;
    margin-top: 30px;
}

#message-box-metadata{
    display: flex;
    flex-direction: row;
    margin-left: 50px; 
    margin-right: 50px;
    margin-bottom: 0px;
    
}

#message-box-metadata p{
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.405); 
    font-size: 12px;
}

#message-inbox-title {
    width: 1000px;
    height: 30px;
    margin-left: 50px;
    margin-right: 50px;
    outline: none;
    border: none;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.282);
}

#message-inbox-content {
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
    width: 1000px;
    height: 150px;
    border-radius: 3px;
    border: none;
    resize: none;
}

#message-box-buttons {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: -20px; 
}

#message-box-send-button {
    float: right;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 3px;
    background-color: #00D084;
    color: white;
}

#message-box-send-button:hover {
    background-color: #009c6f;
    transition: ease-in-out 0.2s;
}

#message-box-delete-button {
    float: right;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 3px;
    background-color: hsl(0, 100%, 40%);
    color: white;
    margin-right: 20px;
}

#message-box-delete-button:hover {
    background-color: hsl(0, 100%, 30%);
}