.navbar {
    background: #E4E5F1;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    width: 100%;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
}

.navbar-container ul {
    list-style: none;
}

.navbar-logo {
    justify-self: start;
    cursor: pointer;


}

.navbar-logo>img {
    height: 50px;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {

    display: flex;
    justify-content: end;

}

.register {
    display: flex;
    flex-direction: column;
    background-color: rgba(82, 65, 84, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 5px;
}

.reg-it {
    color: #fff;
    cursor: pointer;
}

.nav-links {
    /* background-color: red; */
    margin-right: 50px;
    color: #112A46;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 2rem;
    height: 100%;

}

.nav-links:hover {
    border-bottom: 4px solid #112A46;
    transition: all 0.2s ease-out;

}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: white;
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}

#side-menu-nav h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
}

#h2-side-menu {
    font-size: 18px;
    font-weight: bold;
}


.hover-underline-animationHome {
    display: inline-block;
    position: relative;
    color: #112A46;
    font-size: 18px;
    transition: all 0.4s ease-in-out;
    inset: 0;
    padding: 0.5rem 0;
    line-height: 0;
    text-align: center; 
}

.hover-underline-animationHome::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #112A46;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
    border-radius: 5px;
}

.hover-underline-animationHome:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


.editorial {
    display: block;
    width: 100%;
    height: 60px;
    max-height: 60px;
    margin: 0;
    z-index:5;
    bottom:0;
    position:absolute;
    left:0px;
    float:left;
  }
  
  .parallax1 > use {
    animation: move-forever1 10s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
  .parallax2 > use {
    animation: move-forever2 8s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
  .parallax3 > use {
    animation: move-forever3 6s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
  .parallax4 > use {
    animation: move-forever4 4s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
  @keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }
    100% {
      transform: translate(-90px, 0%);
    }
  }
  @keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }
    100% {
      transform: translate(85px, 0%);
    }
  }
  @keyframes move-forever3 {
    0% {
      transform: translate(85px, 0%);
    }
    100% {
      transform: translate(-90px, 0%);
    }
  }
  @keyframes move-forever4 {
    0% {
      transform: translate(-90px, 0%);
    }
    100% {
      transform: translate(85px, 0%);
    }
  }
  