#resetPasswordContainer {
    height: 300px;
}

#resetPasswordContainer h2 {
    text-align: center;
    margin-top: 20px;
}

#resetPasswordContainer p {
    text-align: center;
}

.resForm {
    margin: auto;
    width: 50%;
    padding: 10px;
}

#emailInput {
    margin: auto;
    width: 50%;
}

#emailInput button {
    margin-left: 10px;
    border: none;
    background-color: #00D084;
    color: white;
    height: 50px;
    border-radius: 3px;
    width: 100px;
}

#emailInput button:hover {
    transition: ease-in-out 0.4s;
    background-color: #009c6f;
}

#emailInput input {
    height: 50px;
    width: 250px;
    font-size: 186x;
}