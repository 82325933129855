#resetPasswordContainer input {
    border-radius: 3px;
}

#password-input {
    margin: auto;
    width: 50%;
    width: 350px;
}

#password-input input {
    height: 50px;
}

#password-input button {
    border: none;
    background-color: #00D084;
    color: white;
    height: 50px;
    border-radius: 3px;
    margin-left: 20px;
    width: 100px
}

#password-input button:hover {
    transition: ease-in-out 0.3s;
    background-color: #009c6f;
}