#sliderStyles {
    height: 100%;
    position: relative;
}

#leftArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 8px;
    font-size: 36px;
    color: black;
    opacity: 0.8;
    z-index: 1;
    cursor: pointer;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

#leftArrowStyles:hover {
    color: black;
    opacity: 0.4;
    transition: ease-in-out 0.3s;
}

#rightArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 8px;
    font-size: 36px;
    color: black;
    opacity: 0.8;
    z-index: 1;
    cursor: pointer;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

}

#rightArrowStyles:hover {
    color: black;
    opacity: 0.4;
    transition: ease-in-out 0.3s;
}

#dotsContainerStyles {
    display: flex;
    justify-content: center;
}

#dotStyle {
    margin: 0 12px;
    cursor: pointer;
    font-size: 18px;
}