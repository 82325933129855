@import url('https://fonts.googleapis.com/css?family=Outfit');

.events {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 20px;
}

.selection {
    display: flex;
    flex-direction: column;
}

#venue-title {
    margin-top: 20px;
    text-align: center;
    font-family: "Outfit", sans-serif;
    color: #112A46;
}

.right right-service {
    display: flex;
    flex-direction: column;
}

#num-results-found {
    text-align: center;
    color: #112A46;
    font-family: "Outfit", sans-serif;
    font-size: 18px;
    margin-top: 15px;
}

.select-filter {
    margin-left: 400px;
    margin-right: 500px;
    display: flex;
    justify-content: space-between;
   
    width: 750px; 
}

.select-filter select {
    width: 200px;
    height: 50px;
    margin: auto;
}

#filter-title p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 5px;
}

#filter-sort{
    border: 0.5px solid black; 
    height: 50px; 
    margin-top: 30px;
 
    width: 45px;  
    background-color: white; 
}

#recomendation-text{
    margin-left: 100px; 
}

