#search-result-text {
    margin-top: 50px;
    text-align: center;
    font-size: 32px;
}

.events-search {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 20px;
}

#go-back-btn {
    margin-top: 20px;
    margin-left: 30px;
    border-radius: 5px;
    border: none;
    background-color: #f1f1f1;
    color: black;

}

#go-back-btn:hover {
    background-color: #ddd;
}