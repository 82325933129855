.register-base{
	background: url('../../images/login/welcome.jpg');
	background-size: cover;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.left-image{
	background: url('../../images/login/left-image.png');
	background-size: cover;
	width: 220px;
}
.login-title{
	color: white;
	text-align: center;
	padding-top: 40px;
	padding-bottom: 20px;
	font-size: 28px;
	font-weight: 300;
}
.login-title-2{
	color: white; 
	font-weight: 300; 
	font-size: 20px;
}
.login-card-contatiner{
	background-color: #979CE0;
	border-radius: 10px;
	min-width: 700px;
	position: relative;
	display: flex;
	margin: 20px;
}
.form-conatiner{
	padding: 16px 24px;
	width: 100%;
}
.sub-text-link-gray{
	font-size: 14px;
	color: #484343;
	text-decoration: none;
}
.text-underline{
	text-decoration: underline;
	font-size: 14px;
}
.primary-green-btn{
	background-color: #00D084 !important;
	border: none;
	width: 120px;
	height: 46px;
}
.primary-green-btn:hover{
	background-color: #009c63 !important;
}
.form-check-input{
	width: 1.2rem;
	height: 1.2rem;
	margin-right: 4px;
	cursor: pointer;
}
.form-check-input:checked {
	background-color: #00D084 !important;
	border-color: #00D084 !important;
}
.input-group-text{
	border-top-left-radius: 0.75rem;
	border-bottom-left-radius: 0.75rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	height: 70px;
	width: 61px;
	background-color: white;
	justify-content: center;
}
.form-control{
	height: 70px;
	background-color: #fafafa;
	border-radius: 0.75rem;
}

.icon-gray{
	color: #999999;
}

.user-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 40px 16px 40px;
    background: white;
    border-radius: 10px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.user-icon i{
    font-size: 60px;
}

.user-icon .user-name{
    margin-top: 24px;
    font-size: 24px;
}

@media (max-width: 414px) {
	/*Iphone 12 Pro*/
	.loginBase{
		padding-left: 5vw;
		padding-top: 15vw;
	}
	
	.loginBox{
		width: 90vw;
		padding-top: 6vh;
		height: 60vh;
	}
	.loginBase .loginBox .loginForm{
		margin-top:4vh;
	}
}
@media only screen and (max-width: 1024px) and (min-width: 415px) {
	/* Ipad */
	.loginBase{
		padding-left: 15vw;
		padding-top: 7vw;
	}
	.loginBox{
		padding-top: 3vh;
		width: 70vw;
	}
	.logSend .submit{
		height:  4vh;
		margin-top: 9vh;
	}
	.loginBase .loginBox .loginForm{
		margin-top:4vh;
	}
}
