.carousel-body{
    display: flex; 
    align-items: center;
    justify-content: center;
    background-color: #EFEFEF;
    margin-top: 0px; 
    padding-top: 0px; 
}

.slider-container{
    max-width: 1120px;
    width: 100%; 
    padding: 0px 0;
}

.slide-content{
    margin: 0 30px; 
    margin-bottom: 50px;
}

.card-carousel{
    width: 400px; 
    height: 650px;
    border-radius: 5px;
    background-color: #FFF;
}

.image-content, 
.card-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px; 
}

.image-content{
    position: relative;
    row-gap: 5px; 
}


.card-image{
    position: relative;
    height: 200px; 
    width: 300px;
}

.card-image .card-img{
    height: 100%;
    width: 100%; 
    object-fit: cover;
    background-color: #FFF;
    padding: 3px; 
    border: 4px solid #4080f4;
}

.name{
    font-size: 18px;
    font-weight: 500;
    color: #333; 
}

.description{
    font-size: 14px; 
    color: #707070;
    text-align: justify;
}

.carousel-btn{
    border: none; 
    font-size: 16px;
    color: #FFF; 
    padding: 8px 16px; 
    background-color: #FFC107;
    border-radius: 6px;
    margin: 14px; 
    cursor: pointer;
    transform: all 0.3s ease;
}

.carousel-btn:hover{
    background: #F3B3AC;
}

