:root {
    --primary: white
}

.BTN {
    display: flex;
}

.btn {
    border-radius: 2px;
    outline: none;
    border: 5px solid white;
    font-size: 1rem;
    cursor: pointer;
    width: 35vw;
    position: relative;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;

}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 3rem;
    width: 25vw;
    height: 17vh;
    border: 1px solid white;
}

.btn--medium:hover,
.btn--large:hover {
    background: #6495ed60;
    color: #fff;
    transition: all 0.3s ease-out;
}

.btn .decorate {
    background-color: #ffffff00;
    width: 95%;
    height: 95%;

    border-radius: 5px;
    position: absolute;
    left: 0.5vw;
    top: 0.2vh;
}