@import url('https://fonts.googleapis.com/css?family=Outfit');

* {
    font-family: "Outfit", sans-serif;
}

.card-container {
    background-color: #002135;
    width: 1000px;
    height: 200px;
    margin: 0 auto;
    margin-bottom: 50px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    border-radius: 10px;
    display: flex;
}

.image-container {
    position: relative;
    width: 80%;
    height: 100%;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
}

.image-container #bodyText {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 25%;
    left: 15%;
    margin-right: 10%;
    font-weight: lighter;
}

.image-container #titleText {
    color: #5735c6;
    position: absolute;
    top: 5%;
    left: 15%;
    margin-right: 10%;

}

.image-container #title-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 15%;
    height: 20%;
    background-color: #005AFF;
    border-bottom-right-radius: 10px;
}

.image-container #title-container h2 {
    color: white;
    font-size: 18px;
    font-weight: 400;
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
}

.side-container {
    position: relative;
    width: 20%;
    height: 100%;
    background-color: #E4E5F1;
}


.side-container p {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.button-container {
    position: absolute;
    top: 40%;
    left: 45%;
    transform: translate(-50%, -50%);

}

#viewmore-btn {
    position: relative;
    border: 0;
    display: inline-block;
    width: 12rem;
    height: auto;
    cursor: pointer;
    outline: none;
    vertical-align: middle;
    background: transparent;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #112A46;
    font-size: 18px;
    transition: all 0.4s ease-in-out;
    inset: 0;
    padding: 0.5rem 0;
    margin-left: 1rem;
    line-height: 0;
    text-align: center;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #112A46;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
    border-radius: 5px;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}