@import '~antd/dist/antd.css';

.dashboard{
    height:100vh;
    /* padding-top: 10vh; */
    overflow: hidden;
}
.table{
    margin-top: 3vh;
}
.functions input{
    width:20vw;
}
.functions input::-webkit-input-placeholder{
    color: gray;
    font-size: 1rem;
}

.dashboard-user{
    display: flex;
}
.dashboard-user .m-slide{
    width: 18.5vw;
    height: inherit;
}
.dashboard-user .m-slide .ant-menu-item{
    font-size: 1rem;
}

.dashboard-user .m-content{
    flex:1;
    height: inherit;  
}
.dashboard-user .ant-menu{
    height: 100vh;
}

.dashboard-user .m-operate{
    text-align: right;
    padding-bottom: 10px;
}

.m-readonly-modal .ant-form-item{
    pointer-events: none;
    /* no mouse event could happen */
}

.m-readonly-modal .ant-modal-footer{
    display: none;
}


.dashboard-user .m-filter{
    margin: 10 0;
}