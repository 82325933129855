@import url('https://fonts.googleapis.com/css?family=Outfit');

* {
    font-family: "Outfit", sans-serif;
}

.event-list-card {
    margin: auto;
    background-color: #E4E5F1;
    width: 470px;
    height: 600px;
    border-radius: 7px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /*Temporary Margin and Padding can delete after */
    margin-bottom: 50px;
}

#event-list-card-title {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-right: 1rem;
}

#event-list-card-title img {
    margin-top: 14px;
    width: 36px;
    height: 36px;
    margin-left: 10px;
}

#event-list-card-title p {
    display: flex;
    margin-left: 1rem;
    font-size: 24px;
    text-transform: capitalize;
    margin-top: 12px;
}

#event-list-card-image-preview {
    margin: 0 auto;
    width: 90%;
    background-color: aliceblue;
    border-radius: 7px;
    height: 250px;
}

#image-container {
    width: 100%;
    height: 100%;
    background-color: #E4E5F1;
    border-radius: 7px;
}

#event-list-card-capacity {

    margin: 40px 20px 20px;
    display: flex;
    flex-direction: row;
    height: 36px;
    margin-bottom: 0px;
}

#event-list-card-capacity img {

    height: 32px;
    width: 32px;
    margin-right: 8px;
}

#event-list-card-capacity p {
    margin-top: 0px;
    margin-right: 10px;
    font-size: 24px;

}

#event-standing-capacity {
    display: flex;
    flex-direction: row;
}

#event-seating-capacity {
    display: flex;
    flex-direction: row;
}

#event-list-card-description {
    display: inline-block;
    margin: 0px 20px 20px;
    margin-bottom: 0;
}

#event-list-card-description h1 {
    margin-top: 16px;
    font-size: 18px;
    font-family: "Outfit", sans-serif;
}

#event-list-card-description p {
    font-size: 14px;
    margin-top: 1px;
    text-align: justify;
}

#event-list-card-star-rating {
    margin-left: auto;
    margin-right: 0;
    top: -10px;
}

#event-list-card-buttons {
    margin: 0px 20px 20px;
    height: 50px;
}

#event-list-card-select-btn {
    font-size: 18px;
    width: 65%;
    height: 100%;
    background-color: #ACB2F3;
    color: #112A46;
    border-radius: 5px;
    border: none;
}

#event-list-card-select-btn:hover {
    background-color: #F3B3AC;
    transition: ease-in-out 0;
}

#event-list-card-view-more-btn {
    padding: 0;
    text-decoration: none;
    border: none;
    background-color: #FFC107;
    color: #112A46;
    height: 100%;
    font-size: 18px;
    margin-left: 5%;
    margin-right: 0;
    width: 30%;
    border-radius: 5px;
}

#event-list-card-view-more-btn:hover {
    background-color: #F3B3AC;
    transition: ease-in-out 0
}