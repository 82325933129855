@import url('https://fonts.googleapis.com/css?family=Outfit');

#result_banner_frame {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-size: cover;
    position: relative;
    text-align: center;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
}

#result-banner-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    filter: brightness(60%);
}


#intro-title {
    margin-top: 20px;
    text-align: center;

    display: flex;
    flex-direction: column;

}

#intro-title h2 {
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    font-family: "Outfit", sans-serif;
    font-size: 36px;
    margin-bottom: 0px;
}

#intro-title p {
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 0px;
}


#result-star-rating {
    text-align: center;
    justify-content: center;
    margin-left: 140px;
    margin-right: 100px;
    margin-top: 0px;

}

#imageslider-result {
    margin-top: 50px;
}

#useless-stuff {
    margin-bottom: 1000px
}

#result-secondary-container {
    margin-top: 80px;
    margin-left: 100px;
    margin-right: 100px;
    height: 350px;
    overflow: hidden;
}

#info-container {
    float: left;
    width: 60%;
}

#info-container h2 {
    color: #112A46;
    font-family: "Outfit", sans-serif;
}

#info-container p {
    font-size: 16px;
}

#result-event-buttons {
    margin-right: 10px;
    float: right;
    height: 95%;
    width: 250px;
    background-color: #E4E5F1;
    border-radius: 7px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#result-event-buttons img {
    width: 28px;
    height: 28px;
}

#result-standing {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

#result-standing p {
    margin-left: 20px;
    font-size: 18px;
    text-align: center;
}

#result-seated {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
}

#result-seated p {
    margin-left: 20px;
    font-size: 18px;
    text-align: center;
}

#result-price {
    margin-left: 20px;
    font-size: 18px;
}

#result-buttons button {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    width: 200px;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    margin-top: 20px;
    text-align: center;
}

#result-buttons button:hover {
    background-color: #8993C8;
    transition: ease-in-out 0.2s;
}

#result-buttons img {
    width: 24px;
    height: 24px;
    float: left;
    margin-left: 10px;
    margin-right: 0px;
}

#contact-seller-btn, #review-btn {
    background-color: #ACB2F3;
}

#add-to-cart-btn {
    background-color: #00D084;
}

#result-venue-features {
    margin-left: 100px;
    margin-right: 100px;
}

#result-venue-grid {
    display: grid;
    grid-template-columns: auto auto auto;
}

#result-venue-grid div {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

#result-venue-grid img {
    height: 48px;
    width: 48px;
}

#result-venue-grid p {
    font-size: 18px;
    margin-left: 20px;
    text-align: center;
}

#result-location {
    margin-top: 50px;
    margin-right: 100px;
    margin-left: 100px;
    margin-bottom: 100px;
}

#result-location iframe {
    border-radius: 5px;
}

#modal-chat-container {
    display: flex;
    flex-direction: column;
}

#modal-chat-container label {
    font-size: 18px;
}

#modal-message {
    margin-top: 10px;
}

#modal-message-text {
    height: 100px;
    resize: none;
}

#recm {
    display: flex;
    grid-template-columns: auto auto auto;

}

#recomendation-sample {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 50px;
    width: 900px;
    height: 300px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#recomendation-sample h2 {
    text-align: center;
    font-size: 18px;
}

#recomendation-sample img{
    max-width: 100%;
    max-height: 40%;
    border-radius: 5px;
    margin-left: 35px;

}

#rec1, #rec2, #rec3{
    border-radius: 5px;
    height: 100%;
    width: 250px;
    align-items: center;
}

#rec-btn{
    margin-top: 20px; 
    width: 100%; 
    border-radius: 5px;
    border: none; 
    background-color: #FFC107;

}

#recomend-line{
    display: flex; 
    flex-direction: row;
    margin-left: 100px;
    margin-right: 100px;
    justify-content: space-between;
}