.review-content{
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
    align-items: left;
    width: 90vw;
    height: 90vh;
    margin-bottom: 1vh;
    margin-left: 10vw;
}
.marking h3{
    font-size: 1.4rem;
    margin-right: 10vw;
    text-align: left;
    width: 30vw;
}
.review-title h3{
    font-size: 2rem;
    margin-right: 10vw;
    text-align: left;
    width: 30vw;
}
.review-title h2{
    border: 3px solid rgb(228, 228, 228);
    padding: 10px;
    border-radius: 5px;
}
.comment h3{
    font-size: 2.45rem;
    margin-right: 10vw;
    text-align: left;
    width: 30vw;
}
.comment textarea{
    width: 75vw;
    height: 15vh;
    border-radius: 5px;
    line-height: 2.5vh;
    font-size: medium;
    padding: 1vw;
}
.review-title,.marking{
    display: flex;
}
.uploads h3{
    font-size: 1.4rem;
}
.uploads input{
    width: 75vw;
    height: 26.5vh;
    border: 3px solid rgb(228, 228, 228);
    margin-bottom: 2vh;
    border-radius: 5px;
}
.finish{
    width: 75vw;
    display: flex;
    justify-content: center;
}
.finish button{
    width: 60vw;
    height: 7vh;
    background-color: rgb(226, 205, 127);
    color: aliceblue;
    border: none;
    font-size: 1.4rem;
    cursor: pointer;
}
.review-content .background1{
    background-image: url("https://images.pexels.com/photos/1779414/pexels-photo-1779414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-position: bottom;
    background-size: 70%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    margin-left: -10vw;
}